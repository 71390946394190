import '../styles/HowWorking.css'

function HowWorkingContent() {
    return (
        <div className="working-content">
            <div className="howworking-title reveal">HOW DOES IT WORK?</div>
            <div className='working-container'>
                <div className="working-section1 reveal">
                    <nav className="accordion arrows">
                        <input type="radio" name="accordion" id="hw1" />
                        <section className="box">
                            <label className="box-title" htmlFor='hw1'>AI Automation</label>
                            <label className="box-close" htmlFor="acc-close"></label>
                            <div className="box-content">Quickly responds to incoming emails from potential leads. It checks calendar availability and crafts personalized responses, enhancing the overall lead engagement process.</div>
                        </section>
                        <input type="radio" name="accordion" id="hw2" />
                        <section className="box">
                            <label className="box-title" htmlFor="hw2">Mailbox Management</label>
                            <label className="box-close" htmlFor="acc-close"></label>
                            <div className="box-content">Trust us with your mailbox setup, lead generation, copywriting, campaign sequences, and response management. We guide every step until your qualified meeting is booked.</div>
                        </section>
                        <input type="radio" name="accordion" id="hw3" />
                        <section className="box">
                            <label className="box-title" htmlFor="hw3">Land in Inboxes</label>
                            <label className="box-close" htmlFor="acc-close"></label>
                            <div className="box-content">With ReplyLead, your emails are guaranteed to land in the inboxes of hundreds of thousands of decision-makers. Say goodbye to spam hurdles and welcome seamless connections with your audience. Prepare for a calendar filled with opportunities.</div>
                        </section>
                        <input type="radio" name="accordion" id="acc-close" />
                    </nav>
                </div>
                <div className="working-section2 trans">
                    <div className='how-image'>
                        <img src={'/picture/how_img.png'} alt=''></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowWorkingContent