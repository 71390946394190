import '../styles/MainContent.css'

function MainContent() {
    return (
        <div className='page-main'>
            <div className='main-container'>
                <div className='maintext-animation'>
                    <div>
                        <span className="text-span">6.3x </span>
                    </div>
                    <section className="animation span-underline-use-cases">
                        <div>
                            Meetings
                        </div>
                        <div>
                            Replies
                        </div>
                        <div>
                            Leads
                        </div>
                    </section>
                </div>
                <div className="text-xl-regular">Get 6-16 sales calls every month with ROI-focused AI-powered lead acquisition for high-quality B2B clients. Systemize your outreach for more sales from hard-to-reach prospects.</div>
                <div className='main-btn'>
                    <a href="https://calendly.com/mark-replylead" className="button-3">
                        Book a Call
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MainContent