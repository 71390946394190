import NavbarContent from './NavbarContent';
import MainContent from './MainContent';
import FeatureContent from "./FeatureContent"
import SlideContent from './SlideContent';
import HowWorkingContent from "./HowWorkingContent"
import FaqContent from "./FaqContent"
import FooterContent from './FooterContent';
import DocumentMeta from 'react-document-meta';

function LandingPage() {
    const meta = {
        description: 'Boost B2B lead generation with ReplyLead: 100+ mailboxes for targeting ideal customers using AI and 50+ data points for personalization',

        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'replylead,Email,Generation,B2B'
            }
        }
    };
    return (
        <div>
            <DocumentMeta {...meta}>
                <NavbarContent></NavbarContent>
                <MainContent></MainContent>
                <FeatureContent></FeatureContent>
                <SlideContent></SlideContent>
                <HowWorkingContent></HowWorkingContent>
                <FaqContent></FaqContent>
                <FooterContent></FooterContent>
            </DocumentMeta>
        </div>
    )
}

export default LandingPage