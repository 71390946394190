
import '../styles/Loading.css'
function Loading() {
    return (
        <div className="loadingio-spinner-ellipsis-z0qckxtut8o"><div className="ldio-9doe6q2j3bp">
            <div></div><div></div><div></div><div></div><div></div>
        </div></div>

    );
}

export default Loading;