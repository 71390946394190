import '../styles/FaqContent.css';

function FaqContent() {
    return (
        <div className='faq-content trans'>
            <div className='faq-container'>
                <div className='faq-title'>
                    <p>FAQ</p>
                </div>
                <div className='faq-main'>
                    <nav className="accordion arrows">
                        <input type="radio" name="accordion" id="cb1" />
                        <section className="box">
                            <label className="box-title" htmlFor='cb1'>What is replylead?</label>
                            <label className="box-close" htmlFor="acc-close"></label>
                            <div className="box-content">ReplyLead is a comprehensive email sales agency for B2B businesses, providing services such as custom list building, A/B copy testing, and multi-inbox management. With an AI-driven approach, they enhance email outreach, optimize strategies, and offer Sales Development Representatives for lead qualification through calls. For more details, book a free 15-minute consultation.</div>
                        </section>
                        <input type="radio" name="accordion" id="cb2" />
                        <section className="box">
                            <label className="box-title" htmlFor='cb2'>What is an Email Lead Generation Agency?</label>
                            <label className="box-close" htmlFor="acc-close"></label>
                            <div className="box-content">An email lead generation agency is like a business ally that uses emails to find potential customers for businesses. They create special emails to capture attention and prompt actions, mainly booking calls rather than filling out forms or making purchases.
                                These agencies are experts at organizing email lists and using clever tricks to boost engagement. Some, like ReplyLead, take it a step further. They use personalized outreach and proper targeting to find the right Ideal Customer Profile (ICP). What's unique is that they go beyond emails – they have experts called Sales Development Representatives (SDRs) who chat with interested people on a call to make sure they're a perfect fit. The goal? To book meetings for demos or to qualify the lead before connecting them with the business.</div>
                        </section>
                        <input type="radio" name="accordion" id="cb3" />
                        <section className="box">
                            <label className="box-title" htmlFor="cb3">How do I choose an Email Lead Generation Agency?</label>
                            <label className="box-close" htmlFor="acc-close"></label>
                            <div className="box-content">When selecting an Email Lead Generation Agency, focus on aligning their approach with your business goals. Assess their track record and ensure their strategies match your vision. Confirm their understanding of your target audience, discuss costs, and inquire about adaptability. Seek references for insights into their capabilities. This thorough consideration will guide your decision effectively.</div>
                        </section>
                        <input type="radio" name="accordion" id="cb4" />
                        <section className="box">
                            <label className="box-title" htmlFor="cb4">How Do I Find B2B Clients?</label>
                            <label className="box-close" htmlFor="acc-close"></label>
                            <div className="box-content">To find B2B clients, use LinkedIn, attend industry events, create impactful content, seek referrals, consider cold outreach and partnerships, utilize online directories and social media ads, join professional associations, and maintain strong client relationships. Experiment to find what works best for your business.</div>
                        </section>
                        <input type="radio" name="accordion" id="cb5" />
                        <section className="box">
                            <label className="box-title" htmlFor="cb5">Who are the lead Generation Experts?</label>
                            <label className="box-close" htmlFor="acc-close"></label>
                            <div className="box-content">While there isn't a single superior B2B lead generation service provider, there is undoubtedly a roster of top contenders, with ReplyLead among them. The distinguishing factors among the elite companies encompass the caliber of generated leads, the average cost per lead, and the supplementary appointment setting services offered. Ultimately, conducting thorough research, scrutinizing client testimonials, and perusing user reviews will empower you to form your own informed opinion.</div>
                        </section>

                        <input type="radio" name="accordion" id="acc-close" />
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default FaqContent