
function BlogFooter() {
    return (
        <div className="blogfooter-content">
            <div className='blogfooter-container'>
                <div className="blogfooter-logo">
                    <a href="/" className='back-home'>
                        <img src={'/picture/back.svg'} width={40} alt='back_img' style={{ paddingBottom: '5px' }}></img>
                        <p className="logo" style={{ fontSize: '30px' }}>replylead</p>
                    </a>
                    <p>ReplyLead is an AI Sales Email Lead Agency that helps B2B scale and send emails — faster.</p>
                </div>
                <div className='blogfooter-join' style={{ paddingTop: '10px' }}>
                    <h2>Why Miss Out on Growth?</h2>
                    <p>Guaranteed 5 - 15 Qualified Meetings per Month or It's on Us! Discover the Surefire Way to Boost Your Business – No Risk, Just Rewards! Ready to Take Your Success to the Next Level?</p>
                </div>
            </div>
            <p style={{ marginTop: '50px', fontSize: '13px', textAlign: 'left' }}>© 2024 replylead. All Rights Reserved.</p>
        </div>
    )
}

export default BlogFooter