import '../styles/SlideContent.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function SlideContent() {
    const [jsonData, setJsonData] = useState({});
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const SliceText = ({ children }) => {
        const text = children;
        return (
            <p>
                {text.slice(0, 150) + "....."}
            </p>
        )
    };

    useEffect(() => {
        const fetchData = async () => {
            const jsonData = await fetch('data/data.json')
            const data = await jsonData.json()
            setJsonData(data.datas)
        }
        fetchData()
    }, []);

    return (
        <div>
            <div className='slide-content'>
                <div className='slide-menu'>
                    <div className='slide-in'>
                        <h1 className="slide-menu-title">What's New at <span className='heading-shine'>replylead</span></h1>
                        <p className="slide-menu-desc">Stay up to date on news, learn best practices, and more.</p>
                        <a href='/blog' className='slide-menu-btn'>Read Our Blog</a>
                    </div>
                </div>
                <div className='slide-container'>
                    {
                        !!jsonData && jsonData.length > 0 &&
                        <Carousel
                            showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            arrows={false}
                        >
                            {jsonData?.map((blog, index) => (
                                <div className='slides' key={index}>
                                    <div className='slide-part'>
                                        <div className='slide-image-container'>
                                            <img src={`/images/${blog.attributes.picture}`} className='slide-image' alt=''></img>
                                        </div>
                                        <h5 className="slide-image-title"><b>{blog.attributes.title}</b></h5>
                                        <SliceText>{blog.attributes.introduction}</SliceText>
                                        <Link to={`/blog/${blog.id}`} state={{ blog }} className='slide-btn'>Discover More</Link>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    }
                </div>
            </div>
        </div>
    );
}

export default SlideContent