import './App.css';
import LandingPage from './components/LandingPage'
import BlogContent from './components/BlogContent';
import BlogMore from './components/BlogMore';
import 'bootstrap/dist/css/bootstrap.min.css';
import BackToUp from '@uiw/react-back-to-top';
import './components/ScrollAnimation'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Loading from './components/Loading';
import Automation from './components/Automation'
import DocumentMeta from 'react-document-meta';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for 2 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const meta = {
    description: 'Boost B2B lead generation with ReplyLead: 100+ mailboxes for targeting ideal customers using AI and 50+ data points for personalization',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'replylead,Email,Generation,B2B'
      }
    }
  };

  return (
    <div className="App">
      <DocumentMeta {...meta}>
        {isLoading ? (
          <Loading />
        ) : (
          <BrowserRouter>
            <Routes>
              <Route path='/' Component={LandingPage}></Route>
              <Route path='/automation' Component={Automation}></Route>
              <Route path='/blog' Component={BlogContent}></Route>
              <Route path='/blog/:id' Component={BlogMore}></Route>
            </Routes>
            <BackToUp style={{ width: '40px' }}>Top</BackToUp>
          </BrowserRouter>
        )}
      </DocumentMeta>
    </div>
  )
}

export default App;
