import '../styles/FeatureContent.css';

function FeatureContent() {
    return (
        <div className="feature-content trans">
            <div className="feature-section1">
                <div className="text-container">
                    <div className="textin-container">
                        <div className="heading-and-supporting-text-3">
                            <div className="heading-and-subheading-2">
                                <div className="display-md-semibold">
                                    Scale your outreach, not your team
                                </div>
                            </div>
                            <div className="text-xl-regular-2">No more logging into different tools to execute on your leads
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="feature-section2">
                <div className="grid-container">
                    <div className="feature-text">
                        <div className="featured-icon">
                            <img src={'/picture/grid1.svg'} loading="lazy" width="24" height="24" alt="" className="vectors-wrapper-9"></img>
                        </div>
                        <div className="text-and-supporting-text-4">
                            <div className="text-xl-semibold">Email Deliverability Simplified</div>
                            <div className="text-md-regular-6">We combine rotating mailboxes into one master inbox, guaranteeing seamless email delivery with advanced DNS configuration to enhance security and trustworthiness</div>
                        </div>
                    </div>
                    <div className="feature-text">
                        <div className="featured-icon">
                            <img src={'/picture/grid2.svg'} loading="lazy" width="24" height="24" alt="" className="vectors-wrapper-9"></img>
                        </div>
                        <div className="text-and-supporting-text-4">
                            <div className="text-xl-semibold" style={{ paddingBottom: '30px' }}>Stay out of spam</div>
                            <div className="text-md-regular-6">Warm up every mailbox for high open rates and validate every email address to keep your bounce rate close to 0%</div>
                        </div>
                    </div>
                    <div className="feature-text">
                        <div className="featured-icon">
                            <img src={'/picture/grid3.svg'} loading="lazy" width="24" height="24" alt="" className="vectors-wrapper-9"></img>
                        </div>
                        <div className="text-and-supporting-text-4"><div className="text-xl-semibold">Custom Lists and Personalized Emails </div>
                            <div className="text-md-regular-6">Send 1-1 personalized campaigns that book more meetings: Custom list building and personalized emails work wonders in reaching your ideal audience and making your connections meaningful
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeatureContent