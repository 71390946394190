import { useParams } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import NavbarContent from './NavbarContent';
import BlogFooter from './BlogFooter';
import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Modal from 'react-bootstrap/Modal';

function BlogMore() {
    window.scrollTo(0, 0);
    const params = useParams();

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        setNme('')
        setMail('')
        setMsg('')
    }
    const handleshow = () => setShow(true)

    const [mail, setMail] = useState()
    const [nme, setNme] = useState()
    const [msg, setMsg] = useState()

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”
        emailjs.sendForm('service_fdckn6o', 'template_38bw76n', form.current, '4Fk4n_MFya_6Ohgjr')
            .then((result) => {
                handleshow()
            }, (error) => {
                // show the user an error
            });
    };

    const [blogData, setBlogData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            const blogData = await fetch('/data/data.json')
            const data = await blogData.json()
            await setBlogData(data.datas)
        }
        fetchData()
    }, [])

    return (
        <div>
            <div className='navbar-a'>
                <NavbarContent></NavbarContent>
            </div>
            <Modal show={show} backdrop="static" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Hey, {nme}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    We have received your request and our support team will be in touch with you shortly.<br /><br />
                    Here’s your message:<br /><br />
                    Summary<br />
                    <i>{msg}</i><br /><br />
                    Thanks again,
                    ReplyLead team</Modal.Body>
            </Modal>
            <div className="blogmore-content">
                <a href='/blog'><button className='to-back'><Icon.ArrowLeft size={30}></Icon.ArrowLeft></button></a>
                {
                    blogData.length > 0 &&
                    <div className="blogmore-container">
                        <div className='blogmore-carousel'>
                            {/* {console.log(blogData[params.id].attributes)} */}
                            <img src={`/images/${blogData[params.id].attributes.picture}`} className='blogmore-img' alt='' ></img>
                        </div>
                        <div className="blogmore-desc">
                            <h2>{blogData[params.id].attributes.title}</h2>
                            <p>{blogData[params.id].attributes.introduction}</p>
                            {blogData[params.id].attributes.body.split('\n').map((label, id) => (
                                <p key={id}>{label}</p>
                            ))}
                        </div>
                    </div>
                }
                <div className="blogmore-section2">
                    <div className="blogmore-fixed">
                        <div className="blogmore-logo">
                            <a href="/" className='back-home'>
                                <img src={'/picture/back.svg'} width={50} alt='back_img' style={{ paddingBottom: '10px' }}></img>
                                <p className="logo" style={{ fontSize: '45px' }}>replylead</p>
                            </a>
                        </div>
                        <div>
                            <p style={{ fontSize: '25px', textAlign: 'left' }}>You Are Now Entering replylead</p>
                        </div>
                        <div style={{ marginTop: '5%' }}>

                            <div className='social-icon'>
                                <a href='https://ca.linkedin.com/in/sredoidmtc' target='_blank' rel="noreferrer"><img src={'/picture/social-linkedin.svg'} alt=''></img></a>
                                <a href='https://wa.me/message/QJSHRRUQKEE5B1' target='_blank' rel="noreferrer"><img src={'/picture/social-whatsapp.svg'} alt=''></img></a>
                                <a href='https://t.me/replyleadbot' target='_blank' rel="noreferrer"><img src={'/picture/social-telegram.svg'} alt=''></img></a>
                            </div>
                            <div>
                                <form ref={form} onSubmit={sendEmail} className="blogmore-form">
                                    <div className='blogmore-input'>
                                        <label className='col-md-3'>Your Name:</label>
                                        <input type="text" name="from_name" value={nme} onChange={e => setNme(e.target.value)} className='footer-input col-md-9' />
                                    </div>
                                    <div className='blogmore-input'>
                                        <label className='col-md-3'>Your Email:</label>
                                        <input type="email" name="from_email" value={mail} onChange={e => setMail(e.target.value)} className='footer-input col-md-9' />
                                    </div>
                                    <div className='blogmore-input' style={{ display: 'flex' }}>
                                        <p className="col-md-3">message:</p>
                                        <textarea type='text' name="message" value={msg} onChange={e => setMsg(e.target.value)} style={{ maxHeight: '100px' }} className='footer-input col-md-9' />
                                    </div>
                                    <input type="submit" style={{ float: 'right' }} value="Send" className="subscribe-btn" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BlogFooter></BlogFooter>
        </div >
    )
}

export default BlogMore