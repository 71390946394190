import '../styles/BlogContent.css'
import { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavbarContent from './NavbarContent';
import BlogFooter from './BlogFooter';
import { Link } from 'react-router-dom';

function BlogContent() {
    const [jsonData, setJsonData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };

        return (
            <p className="text">
                {isReadMore ? text.slice(0, 300) : text}
                <span
                    onClick={toggleReadMore}
                    className="read-or-hide"
                    style={{ color: "#de1ade", cursor: 'pointer' }}
                >
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const jsonData = await fetch('/data/data.json')
            const data = await jsonData.json()
            setJsonData(data.datas)
            setIsLoading(false);
        }
        fetchData()
    }, []);

    return (
        <div>
            {isLoading ? <p>Loading...</p> : null}
            <NavbarContent></NavbarContent>
            <div className='blog-content'>
                <div className='title-container'>
                    <h1 className='blog-heading-title'>Mastering Cold Email Strategies for High Deliverability and Targeted Outreach</h1>
                    <p className="heading-content">In the world of B2B lead generation, cold emailing remains an indispensable tool. With the right strategies and technologies, you can enhance email deliverability, automate your outreach, and improve lead segmentation for more personalized campaigns. In this article, we'll delve into the key elements of successful cold email campaigns.</p>
                </div>
                {
                    !!jsonData && jsonData.length > 0 &&
                    <div className='blog-container'>
                        <Container>
                            <Row>
                                {jsonData?.map((blog, index) => (
                                    <Col lg={6} key={index}>
                                        <div className='blog-section'>
                                            <Link to={`/blog/${blog.id}`} state={{ blog }}>
                                                <img src={`/images/${blog.attributes.picture}`} alt='' className='blog-img'></img>
                                            </Link>
                                            <div className='blog-desc'>
                                                <h3>
                                                    {blog.attributes.title}
                                                </h3>
                                                <ReadMore>
                                                    {blog.attributes.introduction}
                                                </ReadMore>
                                                <Link to={`/blog/${blog.id}`} state={{ blog }}>
                                                    Read article
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                                }
                            </Row>
                        </Container>
                    </div>
                }
            </div>
            <BlogFooter></BlogFooter>
        </div >
    )
}

export default BlogContent